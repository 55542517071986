var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { attrs: { flat: "" } }, [
    _c(
      "div",
      { staticClass: "ma-0 pa-0" },
      [
        _c("v-card-title", [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "span",
                { staticClass: "secondary--text font-weight-bold mr-2" },
                [_vm._v("Object distribution")]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c("v-icon", _vm._g({ attrs: { small: "" } }, on), [
                            _vm._v("info"),
                          ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("span", [
                    _vm._v(
                      "Object distribution allows you to selectively send files, tables, and applications across instances or spaces."
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "8", "offset-md": "2" } },
                  [
                    _c(
                      "v-stepper",
                      {
                        attrs: { vertical: "" },
                        model: {
                          value: _vm.stepperStep,
                          callback: function ($$v) {
                            _vm.stepperStep = $$v
                          },
                          expression: "stepperStep",
                        },
                      },
                      [
                        _c(
                          "v-stepper-step",
                          {
                            staticClass: "rounded",
                            attrs: {
                              complete: _vm.stepperStep > 1,
                              color:
                                _vm.stepperStep === 1 ? "primary" : "secondary",
                              editable: _vm.stepperStep > 1,
                              step: 1,
                            },
                          },
                          [_vm._v(" Review staged objects ")]
                        ),
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "1" } },
                          [
                            !_vm.isStagingEmpty &&
                            (_vm.applicationsAutoAdded || _vm.showAddReadMeHint)
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-start my-3 pl-3",
                                  },
                                  [
                                    _vm.showAddReadMeHint
                                      ? _c(
                                          "v-banner",
                                          {
                                            staticClass: "mb-4",
                                            attrs: { width: "100%" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "actions",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            text: "",
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.stageReadmeFile,
                                                          },
                                                        },
                                                        [_vm._v("Stage readme")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              2984872344
                                            ),
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  slot: "icon",
                                                  large: "",
                                                  icon: "info",
                                                  color: "info",
                                                },
                                                slot: "icon",
                                              },
                                              [_vm._v("info")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold secondary--text",
                                              },
                                              [_vm._v("Share README file?")]
                                            ),
                                            _vm._v(
                                              " A readme exists among your files but it is not staged. "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.applicationsAutoAdded &&
                                    _vm.stagingObjects.applications.length > 0
                                      ? _c(
                                          "v-alert",
                                          {
                                            staticClass: "mb-4",
                                            attrs: {
                                              outlined: "",
                                              prominent: "",
                                              dense: "",
                                              type: "success",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-column secondary--text",
                                                staticStyle: {
                                                  "max-width": "800px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold subtitle-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Recent apps auto-staged"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "subtitle-2" },
                                                  [
                                                    _vm._v(
                                                      " Your recent apps were automatically staged to allow you to share recently installed packages / added new applications. "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "success",
                                                          small: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-arrow-right"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "caption font-weight-bold success--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Consider unstaging applications you don't want to share. "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.totalFileSize > Math.pow(1024, 3)
                                      ? _c(
                                          "v-alert",
                                          {
                                            attrs: {
                                              text: "",
                                              dense: "",
                                              type: "error",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " Warning: you're distributing considerable amount of data. Distribution can take up to several minutes! "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.totalAppSize > Math.pow(1024, 3)
                                      ? _c(
                                          "v-alert",
                                          {
                                            attrs: {
                                              text: "",
                                              dense: "",
                                              type: "error",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " Warning: you're distributing many application files. Distribution can take up to several minutes! "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.isStagingEmpty
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-start justify-start w-100",
                                  },
                                  [
                                    _c(
                                      "v-expansion-panels",
                                      {
                                        attrs: { flat: "", multiple: "" },
                                        model: {
                                          value: _vm.stagedObjectsPanel,
                                          callback: function ($$v) {
                                            _vm.stagedObjectsPanel = $$v
                                          },
                                          expression: "stagedObjectsPanel",
                                        },
                                      },
                                      [
                                        _vm.stagingObjects.files.length
                                          ? _c(
                                              "v-expansion-panel",
                                              [
                                                _c(
                                                  "v-expansion-panel-header",
                                                  { staticClass: "px-0" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "secondary--text font-weight-medium",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Files (" +
                                                            _vm._s(
                                                              _vm.stagingObjects
                                                                .files.length
                                                            ) +
                                                            ")"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-expansion-panel-content",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      { attrs: { fluid: "" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "error",
                                                                      text: "",
                                                                      disabled:
                                                                        !_vm
                                                                          .stagingObjects
                                                                          .files
                                                                          .length,
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.clearStaging(
                                                                            "files"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "clear_all"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Unstage all files "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-divider"),
                                                    _c(
                                                      "v-list",
                                                      {
                                                        staticClass: "pa-0",
                                                        attrs: { dense: "" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-group",
                                                          {
                                                            staticClass:
                                                              "pa-0 ma-0",
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm
                                                              .orderedStagedObjects
                                                              .files,
                                                            function (file) {
                                                              return _c(
                                                                "v-list-item",
                                                                {
                                                                  key: file.fid,
                                                                  staticClass:
                                                                    "pa-0",
                                                                  staticStyle: {
                                                                    "max-height":
                                                                      "5px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-avatar",
                                                                    [
                                                                      file.type ===
                                                                        _vm
                                                                          .nuvolosObjectTypes
                                                                          .FOLDER ||
                                                                      file.fileType ===
                                                                        2
                                                                        ? _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " folder "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-file-outline"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "secondary--text subtitle-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-container",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  fluid:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                [
                                                                                  _c(
                                                                                    "v-col",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          cols: "6",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            file.filePath
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-col",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          cols: "6",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.humanFileSize(
                                                                                              file.size
                                                                                            )
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-action",
                                                                    {
                                                                      staticClass:
                                                                        "ma-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              icon: "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.removeFromStaging(
                                                                                  [
                                                                                    file,
                                                                                  ],
                                                                                  _vm
                                                                                    .nuvolosObjectTypes
                                                                                    .FILE
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                  color:
                                                                                    "error",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "cancel"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "pa-0 grey lighten-3",
                                                            staticStyle: {
                                                              "max-height":
                                                                "5px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-avatar",
                                                              {
                                                                attrs: {
                                                                  width: "40",
                                                                },
                                                              }
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "secondary--text subtitle-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-container",
                                                                      {
                                                                        attrs: {
                                                                          fluid:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "6",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Total size:"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "6",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.humanFileSize(
                                                                                          _vm.totalFileSize
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-action",
                                                              {
                                                                attrs: {
                                                                  width: "36",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("v-divider"),
                                        _vm.stagingObjects.tables.length
                                          ? _c(
                                              "v-expansion-panel",
                                              [
                                                _c(
                                                  "v-expansion-panel-header",
                                                  { staticClass: "px-0" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "secondary--text font-weight-medium",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Tables (" +
                                                            _vm._s(
                                                              _vm
                                                                .orderedStagedObjects
                                                                .tables.length
                                                            ) +
                                                            ")"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-expansion-panel-content",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "error",
                                                          disabled:
                                                            !_vm.stagingObjects
                                                              .tables.length,
                                                          text: "",
                                                          small: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.clearStaging(
                                                              "tables"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [_vm._v("clear_all")]
                                                        ),
                                                        _vm._v(
                                                          " Unstage all tables "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-divider"),
                                                    _c(
                                                      "v-list",
                                                      {
                                                        staticClass: "pa-0",
                                                        attrs: { dense: "" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-group",
                                                          {
                                                            staticClass:
                                                              "pa-0 ma-0",
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm
                                                              .orderedStagedObjects
                                                              .tables,
                                                            function (table) {
                                                              return _c(
                                                                "v-list-item",
                                                                {
                                                                  key: table.tid,
                                                                  staticClass:
                                                                    "pa-0",
                                                                  staticStyle: {
                                                                    "max-height":
                                                                      "5px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-avatar",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-table-large"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "secondary--text subtitle-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              table.filePath
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-action",
                                                                    {
                                                                      staticClass:
                                                                        "ma-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              icon: "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.removeFromStaging(
                                                                                  [
                                                                                    table,
                                                                                  ],
                                                                                  _vm
                                                                                    .nuvolosObjectTypes
                                                                                    .TABLE
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                  color:
                                                                                    "error",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "cancel"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.stagingObjects.applications.length
                                          ? _c(
                                              "v-expansion-panel",
                                              [
                                                _c(
                                                  "v-expansion-panel-header",
                                                  { staticClass: "px-0" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "secondary--text font-weight-medium",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Applications (" +
                                                            _vm._s(
                                                              _vm
                                                                .orderedStagedObjects
                                                                .applications
                                                                .length
                                                            ) +
                                                            ") "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-expansion-panel-content",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      { attrs: { fluid: "" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "error",
                                                                      disabled:
                                                                        !_vm
                                                                          .stagingObjects
                                                                          .applications
                                                                          .length,
                                                                      text: "",
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.clearStaging(
                                                                            "applications"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "clear_all"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Unstage all applications "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-divider"),
                                                    _c(
                                                      "v-list",
                                                      {
                                                        staticClass: "pa-0",
                                                        attrs: { dense: "" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-group",
                                                          {
                                                            staticClass:
                                                              "pa-0 ma-0",
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.stagingObjects
                                                              .applications,
                                                            function (
                                                              application
                                                            ) {
                                                              return _c(
                                                                "v-list-item",
                                                                {
                                                                  key: application.aid,
                                                                  staticClass:
                                                                    "pa-0",
                                                                  staticStyle: {
                                                                    "max-height":
                                                                      "5px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-avatar",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "desktop_windows"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "secondary--text subtitle-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-container",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  fluid:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                [
                                                                                  _c(
                                                                                    "v-col",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          cols: "6",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            application.filePath
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-col",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          cols: "6",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.humanFileSize(
                                                                                              application.lib_usage ||
                                                                                                0
                                                                                            )
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-action",
                                                                    {
                                                                      staticClass:
                                                                        "ma-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              icon: "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.removeFromStaging(
                                                                                  [
                                                                                    application,
                                                                                  ],
                                                                                  _vm
                                                                                    .nuvolosObjectTypes
                                                                                    .APPLICATION
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                  color:
                                                                                    "error",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "cancel"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "pa-0 grey lighten-3",
                                                            staticStyle: {
                                                              "max-height":
                                                                "5px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-avatar",
                                                              {
                                                                attrs: {
                                                                  width: "40",
                                                                },
                                                              }
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "secondary--text subtitle-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-container",
                                                                      {
                                                                        attrs: {
                                                                          fluid:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "6",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Total size:"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "6",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.humanFileSize(
                                                                                          _vm.totalAppSize
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-action",
                                                              {
                                                                attrs: {
                                                                  width: "36",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-alert",
                                  {
                                    attrs: {
                                      text: "",
                                      type: "info",
                                      prominent: "",
                                      "max-width": "600",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v("Stage is empty"),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        "In order to start a distribution operation, you must first stage objects such as files, tables, and applications."
                                      ),
                                    ]),
                                    _c("v-divider", {
                                      staticClass: "mt-4 mb-1 info",
                                      staticStyle: { opacity: "0.22" },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center flex-wrap justify-end mt-2",
                                      },
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "info",
                                              outlined: "",
                                              small: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.stageObjects(
                                                  _vm.nuvolosObjectTypes.FILE
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("stage files")]
                                        ),
                                        !_vm.isTrialSpace
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "ml-1",
                                                attrs: {
                                                  color: "info",
                                                  outlined: "",
                                                  small: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.stageObjects(
                                                      _vm.nuvolosObjectTypes
                                                        .APPLICATION
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" stage applications ")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "ml-1",
                                            attrs: {
                                              color: "info",
                                              outlined: "",
                                              small: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.stageObjects(
                                                  _vm.nuvolosObjectTypes.TABLE
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("stage tables")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                            _c(
                              "div",
                              { staticClass: "mt-5" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "primary",
                                      disabled: _vm.isStagingEmpty,
                                      "e2e-staging-step-1": "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.isSpaceAdmin
                                          ? (_vm.stepperStep = 2)
                                          : (_vm.stepperStep = 3)
                                      },
                                    },
                                  },
                                  [_vm._v(" Continue ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-step",
                          {
                            staticClass: "rounded",
                            attrs: {
                              color:
                                _vm.stepperStep === 2 ? "primary" : "secondary",
                              step: 2,
                              editable: _vm.isSpaceAdmin && _vm.stepperStep > 2,
                              complete: _vm.stepperStep > 2,
                            },
                          },
                          [
                            _c("div", { staticClass: "d-flex align-center" }, [
                              _vm._v("Save Bundle"),
                            ]),
                            _vm.stepperStep === 2
                              ? _c(
                                  "small",
                                  { staticClass: "mt-1" },
                                  [
                                    _vm.currentSpaceType ===
                                    _vm.spaceTypes.EDUCATION_SPACE
                                      ? [
                                          _vm._v(
                                            " You can save the list of distributed objects as a named bundle for future reference, or you can also create assignments or a learning module from them. "
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            "You can save the list of distributed objects as a named bundle for future reference."
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "2" } },
                          [
                            _vm.bundleTab === 1 &&
                            _vm.totalFileSize > Math.pow(1024, 2) * 10
                              ? _c(
                                  "v-alert",
                                  {
                                    attrs: {
                                      text: "",
                                      dense: "",
                                      type: "error",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Warning: any file selected as part of an assignment will be duplicated verbatim in every handin and handout. This can lead to exhaustion of storage space in the distributed instance, where the handin and handout data is stored. Please consider sharing data files in regular distributions with students, and only add the files to the assignment that need to be edited by the students. "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.currentSpaceType ===
                            _vm.spaceTypes.EDUCATION_SPACE
                              ? _c(
                                  "v-list",
                                  { attrs: { nav: "" } },
                                  [
                                    _c(
                                      "v-list-item-group",
                                      {
                                        attrs: {
                                          mandatory: "",
                                          color: "primary",
                                        },
                                        model: {
                                          value: _vm.bundleTab,
                                          callback: function ($$v) {
                                            _vm.bundleTab = $$v
                                          },
                                          expression: "bundleTab",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c(
                                                  "v-list-item",
                                                  { attrs: { value: 0 } },
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      {
                                                        staticClass:
                                                          "justify-center",
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("inventory"),
                                                        ]),
                                                        _vm._v(
                                                          " Distribution "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c(
                                                  "v-list-item",
                                                  { attrs: { value: 1 } },
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      {
                                                        staticClass:
                                                          "justify-center",
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("assignment"),
                                                        ]),
                                                        _vm._v(" Assignment "),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-tabs-items",
                              {
                                staticStyle: { background: "transparent" },
                                model: {
                                  value: _vm.bundleTab,
                                  callback: function ($$v) {
                                    _vm.bundleTab = $$v
                                  },
                                  expression: "bundleTab",
                                },
                              },
                              [
                                _c(
                                  "v-tab-item",
                                  [
                                    _vm.selectedDistributionReason ===
                                      _vm.distributionSaveOptions.PUBLIC ||
                                    _vm.selectedDistributionReason ===
                                      _vm.distributionSaveOptions.ADMIN_ONLY
                                      ? _c(
                                          "v-form",
                                          {
                                            model: {
                                              value: _vm.validBundle,
                                              callback: function ($$v) {
                                                _vm.validBundle = $$v
                                              },
                                              expression: "validBundle",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-container",
                                              { attrs: { fluid: "" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: {
                                                      align: "center",
                                                      "no-gutters": "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "subtitle-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Bundle name"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "9",
                                                        },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          ref: "bundleNameInput",
                                                          attrs: {
                                                            autofocus: "",
                                                            rules: [
                                                              _vm.rules
                                                                .required,
                                                            ],
                                                            required: "",
                                                            outlined: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.bundleName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.bundleName =
                                                                $$v
                                                            },
                                                            expression:
                                                              "bundleName",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab-item",
                                  [
                                    _vm.currentSpaceType ===
                                      _vm.spaceTypes.EDUCATION_SPACE &&
                                    (_vm.selectedDistributionReason ===
                                      _vm.distributionSaveOptions.PUBLIC ||
                                      _vm.selectedDistributionReason ===
                                        _vm.distributionSaveOptions.ADMIN_ONLY)
                                      ? _c(
                                          "v-form",
                                          {
                                            model: {
                                              value: _vm.validBundle,
                                              callback: function ($$v) {
                                                _vm.validBundle = $$v
                                              },
                                              expression: "validBundle",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-container",
                                              { attrs: { fluid: "" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _vm.stagingObjects
                                                          .tables.length > 0
                                                          ? _c(
                                                              "v-card",
                                                              {
                                                                attrs: {
                                                                  elevation:
                                                                    "5",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-alert",
                                                                  {
                                                                    staticClass:
                                                                      "ma-0 secondary--text",
                                                                    attrs: {
                                                                      color:
                                                                        "error",
                                                                      border:
                                                                        "left",
                                                                      "colored-border":
                                                                        "",
                                                                      icon: "error",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-bold",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Assigments can't have tables"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "subtitle-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " You can not create assignments because you have staged some tables, please unstage tables to create an assignment. "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: {
                                                      align: "center",
                                                      "no-gutters": "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "subtitle-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Assignment name"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "9",
                                                        },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          ref: "bundleNameInput",
                                                          attrs: {
                                                            autofocus: "",
                                                            rules: [
                                                              _vm.rules
                                                                .required,
                                                            ],
                                                            required: "",
                                                            outlined: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.bundleName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.bundleName =
                                                                $$v
                                                            },
                                                            expression:
                                                              "bundleName",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm.selectedDistributionReason ===
                                                _vm.distributionSaveOptions
                                                  .PUBLIC
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        attrs: {
                                                          align: "center",
                                                          "no-gutters": "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "subtitle-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Assignment description"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "9",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                outlined: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.assignmentDescription,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.assignmentDescription =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "assignmentDescription",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.selectedDistributionReason ===
                                                _vm.distributionSaveOptions
                                                  .PUBLIC
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        attrs: {
                                                          align: "center",
                                                          "no-gutters": "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "subtitle-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Hand-in deadline"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "9",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-column",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-menu",
                                                                  {
                                                                    attrs: {
                                                                      "close-on-content-click": false,
                                                                      "nudge-right": 40,
                                                                      transition:
                                                                        "scale-transition",
                                                                      "offset-y":
                                                                        "",
                                                                      "min-width":
                                                                        "auto",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                              attrs,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            readonly:
                                                                                              "",
                                                                                            label:
                                                                                              "Date",
                                                                                            rules:
                                                                                              [
                                                                                                _vm
                                                                                                  .rules
                                                                                                  .required,
                                                                                              ],
                                                                                            required:
                                                                                              "",
                                                                                            outlined:
                                                                                              "",
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm.bundleDeadlineDate,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.bundleDeadlineDate =
                                                                                                  $$v
                                                                                              },
                                                                                            expression:
                                                                                              "bundleDeadlineDate",
                                                                                          },
                                                                                      },
                                                                                      "v-text-field",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        1147046533
                                                                      ),
                                                                    model: {
                                                                      value:
                                                                        _vm.bundleDateMenu,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.bundleDateMenu =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "bundleDateMenu",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-date-picker",
                                                                      {
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.bundleDateMenu = false
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.bundleDeadlineDate,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.bundleDeadlineDate =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "bundleDeadlineDate",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-menu",
                                                                  {
                                                                    attrs: {
                                                                      "close-on-content-click": false,
                                                                      transition:
                                                                        "scale-transition",
                                                                      "offset-y":
                                                                        "",
                                                                      "max-width":
                                                                        "310px",
                                                                      "min-width":
                                                                        "310px",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                              attrs,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            label:
                                                                                              "Time",
                                                                                            readonly:
                                                                                              "",
                                                                                            rules:
                                                                                              [
                                                                                                _vm
                                                                                                  .rules
                                                                                                  .required,
                                                                                              ],
                                                                                            required:
                                                                                              "",
                                                                                            outlined:
                                                                                              "",
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm.bundleDeadlineTime,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.bundleDeadlineTime =
                                                                                                  $$v
                                                                                              },
                                                                                            expression:
                                                                                              "bundleDeadlineTime",
                                                                                          },
                                                                                      },
                                                                                      "v-text-field",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        969350597
                                                                      ),
                                                                    model: {
                                                                      value:
                                                                        _vm.bundleTimeMenu,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.bundleTimeMenu =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "bundleTimeMenu",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "px-2",
                                                                      },
                                                                      [
                                                                        _vm.bundleTimeMenu
                                                                          ? _c(
                                                                              "time-picker",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    format:
                                                                                      "24hr",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm.bundleDeadlineTime,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.bundleDeadlineTime =
                                                                                          $$v
                                                                                      },
                                                                                    expression:
                                                                                      "bundleDeadlineTime",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.bundleHandinDatetime
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Displayed deadline:"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "font-weight-medium ml-1",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "dateTimeToHuman"
                                                                                )(
                                                                                  _vm.bundleHandinDatetime
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex align-center mt-5" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "primary",
                                      loading: _vm.creatingBundle,
                                      disabled: _vm.disabledStepThree,
                                      "e2e-staging-step-2": "",
                                    },
                                    on: { click: _vm.moveToStepThree },
                                  },
                                  [_vm._v(" Continue ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      disabled:
                                        _vm.loading || _vm.creatingBundle,
                                      color: "secondary",
                                      text: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.stepperStep = 1
                                      },
                                    },
                                  },
                                  [_vm._v("Back")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-step",
                          {
                            staticClass: "rounded",
                            attrs: {
                              color:
                                _vm.stepperStep === 3 ? "primary" : "secondary",
                              editable: !_vm.bundleTab && _vm.stepperStep > 3,
                              complete: _vm.stepperStep > 3,
                              step: 3,
                            },
                          },
                          [_vm._v(" Select Target ")]
                        ),
                        _c("v-stepper-content", { attrs: { step: "3" } }, [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column pl-3" },
                            [
                              _c("v-select", {
                                staticClass: "mb-3",
                                attrs: {
                                  items: _vm.orgList,
                                  "item-text": "long_id",
                                  "item-value": "oid",
                                  label: "Select Organization",
                                  hint: "Target organization",
                                  "persistent-hint": "",
                                  "single-line": "",
                                  dense: "",
                                  outlined: "",
                                  disabled:
                                    _vm.fetchingOrgData ||
                                    _vm.fetchingSpaceData,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.getOrgData($event)
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-item",
                                    fn: function () {
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "px-4",
                                          attrs: {
                                            autofocus: "",
                                            label: "Filter",
                                            outlined: "",
                                            dense: "",
                                          },
                                          model: {
                                            value: _vm.orgSearch,
                                            callback: function ($$v) {
                                              _vm.orgSearch = $$v
                                            },
                                            expression: "orgSearch",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.selectedOrg,
                                  callback: function ($$v) {
                                    _vm.selectedOrg = $$v
                                  },
                                  expression: "selectedOrg",
                                },
                              }),
                              _vm.selectedOrg && _vm.selectedOrgSpaces.length
                                ? _c("v-select", {
                                    attrs: {
                                      items: _vm.spaceList,
                                      "item-text": "long_id",
                                      "item-value": "sid",
                                      hint: "Target space",
                                      "persistent-hint": "",
                                      label: _vm.fetchingOrgData
                                        ? "fetching spaces"
                                        : "Select Space",
                                      "single-line": "",
                                      dense: "",
                                      outlined: "",
                                      disabled:
                                        _vm.fetchingOrgData ||
                                        _vm.fetchingSpaceData,
                                      "e2e-snapshot-select-space": "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.getSpaceData($event)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend-item",
                                          fn: function () {
                                            return [
                                              _c("v-text-field", {
                                                staticClass: "px-4",
                                                attrs: {
                                                  autofocus: "",
                                                  label: "Filter",
                                                  outlined: "",
                                                  dense: "",
                                                },
                                                model: {
                                                  value: _vm.spaceSearch,
                                                  callback: function ($$v) {
                                                    _vm.spaceSearch = $$v
                                                  },
                                                  expression: "spaceSearch",
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2081232596
                                    ),
                                    model: {
                                      value: _vm.selectedSpace,
                                      callback: function ($$v) {
                                        _vm.selectedSpace = $$v
                                      },
                                      expression: "selectedSpace",
                                    },
                                  })
                                : _vm.selectedOrg &&
                                  !_vm.fetchingOrgData &&
                                  !_vm.selectedOrgSpaces.length
                                ? _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        prominent: "",
                                        text: "",
                                        type: "warning",
                                        "max-width": "600",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " You don't have an editor role in any of the spaces of the selected organization. "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.selectedSpace &&
                              !_vm.fetchingOrgData &&
                              !_vm.fetchingSpaceData
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          model: {
                                            value: _vm.allOrSpecificInstances,
                                            callback: function ($$v) {
                                              _vm.allOrSpecificInstances = $$v
                                            },
                                            expression:
                                              "allOrSpecificInstances",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              disabled:
                                                !_vm.isMasterInstance ||
                                                !_vm.isEditorOfDistributed ||
                                                _vm.targetSpaceType !==
                                                  _vm.spaceTypes
                                                    .EDUCATION_SPACE ||
                                                !_vm.selectedSpace ||
                                                _vm.selectedSpace.toString() !==
                                                  _vm.$route.params.sid.toString(),
                                              label: _vm.shareWithAllLabel,
                                              value: "shareWithAllInstances",
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              disabled:
                                                !_vm
                                                  .selectedSpaceAvailableTargetInstances
                                                  .length,
                                              label:
                                                _vm.shareWithOneOrSomeLabel,
                                              value:
                                                "shareWithSpecificInstances",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showInstancesTable
                                ? _c(
                                    "div",
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          "show-select": "",
                                          headers: _vm.headers,
                                          items: _vm.instanceFetching
                                            ? []
                                            : _vm.selectedSpaceAvailableTargetInstances,
                                          "single-select": _vm.isDevelopment,
                                          "items-per-page": -1,
                                          search: _vm.instanceSearch,
                                          "item-key": "iid",
                                          loading: _vm.fetchingSpaceData,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "top",
                                              fn: function () {
                                                return [
                                                  _vm.isEditorOfDistributed &&
                                                  _vm.targetSpaceType ===
                                                    _vm.spaceTypes
                                                      .EDUCATION_SPACE &&
                                                  _vm.selectedSpace &&
                                                  _vm.selectedSpace.toString() ===
                                                    _vm.$route.params.sid.toString()
                                                    ? _c(
                                                        "v-alert",
                                                        {
                                                          attrs: {
                                                            text: "",
                                                            type: "warning",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-column",
                                                              staticStyle: {
                                                                "max-width":
                                                                  "800px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Limited distribution"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  " With this option, only the selected student "
                                                                ),
                                                                _vm.currentSpaceType ===
                                                                  _vm.spaceTypes
                                                                    .EDUCATION_SPACE &&
                                                                _vm.isDevelopment
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "instance"
                                                                      ),
                                                                    ])
                                                                  : _vm.currentSpaceType ===
                                                                      _vm
                                                                        .spaceTypes
                                                                        .EDUCATION_SPACE &&
                                                                    !_vm.isDevelopment
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "instances"
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                _vm._v(
                                                                  " will receive your objects. If you want all student instances to receive the same contents, select "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "font-weight-bold",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.shareWithAllLabel
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "append-icon":
                                                        "mdi-magnify",
                                                      label: "Search",
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      autofocus: "",
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    model: {
                                                      value: _vm.instanceSearch,
                                                      callback: function ($$v) {
                                                        _vm.instanceSearch = $$v
                                                      },
                                                      expression:
                                                        "instanceSearch",
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: `item.long_id`,
                                              fn: function ({ item }) {
                                                return [
                                                  _vm._v(_vm._s(item.long_id)),
                                                ]
                                              },
                                            },
                                            {
                                              key: `item.description`,
                                              fn: function ({ item }) {
                                                return [
                                                  _vm._v(
                                                    _vm._s(item.description)
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.selectedInstances,
                                          callback: function ($$v) {
                                            _vm.selectedInstances = $$v
                                          },
                                          expression: "selectedInstances",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.selectedSpace &&
                              !_vm.fetchingSpaceData &&
                              !_vm.selectedSpaceAvailableTargetInstances
                                .length &&
                              _vm.isEditorOfDistributed &&
                              _vm.targetSpaceType ===
                                _vm.spaceTypes.EDUCATION_SPACE
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "d-flex",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            prominent: "",
                                            outlined: "",
                                            type: "success",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex flex-column",
                                              staticStyle: {
                                                "max-width": "800px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "subtitle-1 ma-0 font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    "Distributing before inviting"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "ma-0" },
                                                [
                                                  _vm._v(
                                                    " Because you haven't invited any students yet, shared material will be available to new students after you have invited them. "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.selectedSpace &&
                                  !_vm.fetchingSpaceData &&
                                  !_vm.selectedSpaceAvailableTargetInstances
                                    .length
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "d-flex",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            prominent: "",
                                            text: "",
                                            type: "warning",
                                            "max-width": "600",
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "subtitle-1 ma-0 font-weight-bold",
                                            },
                                            [_vm._v("No targets available")]
                                          ),
                                          _c("ul", [
                                            !_vm.isEditorOfDistributed
                                              ? _c("li", [
                                                  _vm._v(
                                                    "You have insufficient privileges to distribute to everyone."
                                                  ),
                                                ])
                                              : _vm._e(),
                                            !_vm.isSpaceAdmin
                                              ? _c("li", [
                                                  _vm._v(
                                                    " There are no instances you are an editor of in this "
                                                  ),
                                                  _vm.targetSpaceType ===
                                                  _vm.spaceTypes.EDUCATION_SPACE
                                                    ? _c("span", [
                                                        _vm._v("course"),
                                                      ])
                                                    : _vm.targetSpaceType ===
                                                      _vm.spaceTypes
                                                        .RESEARCH_SPACE
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "research project"
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v("space"),
                                                      ]),
                                                  _vm._v(" . "),
                                                ])
                                              : _c("li", [
                                                  _vm._v(
                                                    "There are no target instances to distribute to in this space"
                                                  ),
                                                ]),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading:
                                      _vm.fetchingOrgData ||
                                      _vm.fetchingSpaceData,
                                    disabled:
                                      _vm.disableContinueInstanceSelection,
                                    "e2e-staging-step-3": "",
                                  },
                                  on: {
                                    click: _vm.setSelectedSpaceTableStatus,
                                  },
                                },
                                [_vm._v(" Continue ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    disabled: _vm.loading,
                                    color: "secondary",
                                    text: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.stepperStep = _vm.isSpaceAdmin ? 2 : 1
                                    },
                                  },
                                },
                                [_vm._v("Back")]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "v-stepper-step",
                          {
                            staticClass: "rounded",
                            attrs: {
                              color:
                                _vm.stepperStep === 4 ? "primary" : "secondary",
                              editable: _vm.stepperStep > 4,
                              complete: _vm.stepperStep > 4,
                              step: 4,
                            },
                          },
                          [_vm._v(" Notification ")]
                        ),
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "4" } },
                          [
                            _c(
                              "v-container",
                              { attrs: { fluid: "" } },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "mt-0",
                                          attrs: {
                                            label:
                                              "Notify recipients of this bundle",
                                          },
                                          model: {
                                            value: _vm.notifyTargetUsers,
                                            callback: function ($$v) {
                                              _vm.notifyTargetUsers = $$v
                                            },
                                            expression: "notifyTargetUsers",
                                          },
                                        }),
                                        _vm.notifyTargetUsers
                                          ? _c("v-textarea", {
                                              attrs: {
                                                autofocus: "",
                                                rows: "2",
                                                "auto-grow": "",
                                                outlined: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(
                                                          "Your message (optional)"
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                791096270
                                              ),
                                              model: {
                                                value: _vm.notificationText,
                                                callback: function ($$v) {
                                                  _vm.notificationText = $$v
                                                },
                                                expression: "notificationText",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "align-center" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              "e2e-staging-step-4": "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.stepperStep = 5
                                              },
                                            },
                                          },
                                          [_vm._v("Continue")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              color: "secondary",
                                              text: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.stepperStep = _vm.bundleTab
                                                  ? 2
                                                  : 3
                                              },
                                            },
                                          },
                                          [_vm._v("Back")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-step",
                          {
                            staticClass: "rounded",
                            attrs: {
                              color:
                                _vm.stepperStep === 5 ? "primary" : "secondary",
                              editable: _vm.stepperStep > 5,
                              complete: _vm.stepperStep > 5,
                              step: 5,
                            },
                          },
                          [_vm._v(" Select Distribution Mode ")]
                        ),
                        _c("v-stepper-content", { attrs: { step: "5" } }, [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column pl-3" },
                            [
                              _c(
                                "v-list",
                                { attrs: { nav: "" } },
                                [
                                  _c(
                                    "v-list-item-group",
                                    {
                                      staticClass: "mt-5",
                                      attrs: {
                                        mandatory: "",
                                        color: _vm.customDistribution
                                          ? "secondary"
                                          : "primary",
                                      },
                                      model: {
                                        value: _vm.distributionStrategy,
                                        callback: function ($$v) {
                                          _vm.distributionStrategy = $$v
                                        },
                                        expression: "distributionStrategy",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "4" } },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  attrs: {
                                                    value:
                                                      _vm
                                                        .availableDistributionStrategyOptions[0]
                                                        .backendCode,
                                                    disabled:
                                                      _vm.customDistribution,
                                                  },
                                                },
                                                [
                                                  _c("v-list-item-content", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-column align-center justify-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u([
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {
                                                                                staticClass:
                                                                                  "mr-1",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "secondary",
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " mdi-information-outline "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ]),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    " Distribute all your objects, and if a similar object exists in the target instance then it will be overwritten. "
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-uppercase",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "overwrite"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("img", {
                                                          attrs: {
                                                            width: "100%",
                                                            src: require("@/assets/overwrite.svg"),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "4" } },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  attrs: {
                                                    value:
                                                      _vm
                                                        .availableDistributionStrategyOptions[1]
                                                        .backendCode,
                                                    disabled:
                                                      _vm.customDistribution,
                                                  },
                                                },
                                                [
                                                  _c("v-list-item-content", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-column align-center justify-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u([
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {
                                                                                staticClass:
                                                                                  "mr-1",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "secondary",
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " mdi-information-outline "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ]),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    " Among the objects you are distributing, only those that do not exist in the target instances will be shared. "
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-uppercase",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "distribute extra"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("img", {
                                                          attrs: {
                                                            width: "100%",
                                                            src: require("@/assets/distribute_extra.svg"),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "4" } },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass: "pa-0 ma-0",
                                                  attrs: {
                                                    value:
                                                      _vm
                                                        .availableDistributionStrategyOptions[2]
                                                        .backendCode,
                                                    disabled:
                                                      _vm.customDistribution,
                                                  },
                                                },
                                                [
                                                  _c("v-list-item-content", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-column align-center justify-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u([
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {
                                                                                staticClass:
                                                                                  "mr-1",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "secondary",
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " mdi-information-outline "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ]),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    " Erease all objects in the target instance, and replace them with the objects that you are sharing. "
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-uppercase",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "clear target and replace"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("img", {
                                                          attrs: {
                                                            width: "100%",
                                                            src: require("@/assets/clear_target.svg"),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          label:
                                            "Snapshot target before distributing",
                                        },
                                        model: {
                                          value:
                                            _vm.snapshotTargetBeforeDistributing,
                                          callback: function ($$v) {
                                            _vm.snapshotTargetBeforeDistributing =
                                              $$v
                                          },
                                          expression:
                                            "snapshotTargetBeforeDistributing",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.snapshotTargetBeforeDistributing
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "mt-5",
                                      attrs: {
                                        type: "success",
                                        outlined: "",
                                        prominent: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ma-0 font-weight-bold",
                                            },
                                            [_vm._v("Backup Snapshot")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              " A snapshot of the current state of each target instance will be taken. This will allow users to recover or restore overwritten files. "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : !_vm.snapshotTargetBeforeDistributing &&
                                  _vm.targetSpaceTablesAreEnabled
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "mt-5",
                                      attrs: {
                                        type: "warning",
                                        text: "",
                                        prominent: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ma-0 font-weight-bold",
                                            },
                                            [_vm._v("Backup Snapshot")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              " Since tables are enabled in the target space, target instance(s) will "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "font-weight-bold",
                                              },
                                              [_vm._v("not")]
                                            ),
                                            _vm._v(
                                              " be snapshotted before distribution for increased performance. This is recommended only for distributing completely new material to avoid unrecoverable data loss in the target(s). Snapshotting still can be turned on, however distribution will be significantly slower. "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : !_vm.snapshotTargetBeforeDistributing &&
                                  !_vm.targetSpaceTablesAreEnabled
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "mt-5",
                                      attrs: {
                                        type: "warning",
                                        text: "",
                                        prominent: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex flex-column",
                                          staticStyle: { "max-width": "800px" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ma-0 font-weight-bold",
                                            },
                                            [_vm._v("Backup Snapshot")]
                                          ),
                                          _c("span", [
                                            _vm._v(" Target instance(s) will "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "font-weight-bold",
                                              },
                                              [_vm._v("not")]
                                            ),
                                            _vm._v(
                                              " be snapshotted before distribution. "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("v-switch", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            class: _vm.customDistribution
                                              ? "subtitle-1 font-weight-bold secondary--text"
                                              : "subtitle-1",
                                          },
                                          [_vm._v(" Custom Distribution ")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.customDistribution,
                                  callback: function ($$v) {
                                    _vm.customDistribution = $$v
                                  },
                                  expression: "customDistribution",
                                },
                              }),
                              _c("div", [
                                _vm.customDistribution
                                  ? _c(
                                      "div",
                                      [
                                        _vm.selectedObjectTypes.includes(
                                          _vm.userWorkAreas.WORKSPACE
                                        )
                                          ? _c(
                                              "v-row",
                                              { attrs: { align: "center" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [_vm._v("Workspace files")]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "8" } },
                                                  [
                                                    _c("v-select", {
                                                      staticClass: "fileSelect",
                                                      attrs: {
                                                        items:
                                                          _vm.availableDistributionStrategyOptions,
                                                        "persistent-hint": "",
                                                        "item-text": "text",
                                                        "item-value":
                                                          "backendCode",
                                                        "single-line": "",
                                                        outlined: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.workspaceDistMode,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.workspaceDistMode =
                                                            $$v
                                                        },
                                                        expression:
                                                          "workspaceDistMode",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.selectedObjectTypes.includes(
                                          _vm.userWorkAreas.PERSONAL
                                        )
                                          ? _c(
                                              "v-row",
                                              { attrs: { align: "center" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [_vm._v("Personal files")]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "8" } },
                                                  [
                                                    _c("v-select", {
                                                      staticClass: "fileSelect",
                                                      attrs: {
                                                        items:
                                                          _vm.availableDistributionStrategyOptions,
                                                        "item-text": "text",
                                                        "item-value":
                                                          "backendCode",
                                                        outlined: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.personalFilesDistMode,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.personalFilesDistMode =
                                                            $$v
                                                        },
                                                        expression:
                                                          "personalFilesDistMode",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.selectedObjectTypes.includes(
                                          _vm.nuvolosObjectTypes.TABLE
                                        )
                                          ? _c(
                                              "v-row",
                                              { attrs: { align: "center" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "3",
                                                    },
                                                  },
                                                  [_vm._v("Tables")]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "8" } },
                                                  [
                                                    _c("v-select", {
                                                      staticClass: "fileSelect",
                                                      attrs: {
                                                        items:
                                                          _vm.availableDistributionStrategyOptions,
                                                        "item-text": "text",
                                                        "item-value":
                                                          "backendCode",
                                                        outlined: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.tablesDistMode,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.tablesDistMode =
                                                            $$v
                                                        },
                                                        expression:
                                                          "tablesDistMode",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center mt-5" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary",
                                          "e2e-staging-step-5": "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.stepperStep = 6
                                          },
                                        },
                                      },
                                      [_vm._v("Continue")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-2",
                                        attrs: {
                                          disabled: _vm.loading,
                                          color: "secondary",
                                          text: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.stepperStep = 4
                                          },
                                        },
                                      },
                                      [_vm._v("Back")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "v-stepper-step",
                          {
                            staticClass: "rounded",
                            attrs: { color: "primary", step: 6 },
                          },
                          [_vm._v("Overview")]
                        ),
                        _c("v-stepper-content", { attrs: { step: 6 } }, [
                          _c(
                            "div",
                            { staticClass: "pl-3" },
                            [
                              _c(
                                "v-container",
                                { attrs: { fluid: "" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "text-caption text-uppercase",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.bundleTab
                                                  ? "Assignment"
                                                  : "Bundle"
                                              ) + " name:"
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("v-col", [
                                        _vm._v(_vm._s(_vm.bundleName)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "text-caption text-uppercase",
                                          },
                                          [_vm._v("Target:")]
                                        ),
                                      ]),
                                      _c("v-col", [
                                        _c(
                                          "div",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.getSelectedOrgName) +
                                                " "
                                            ),
                                            _c("v-icon", [
                                              _vm._v("keyboard_arrow_right"),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.selectedSpaceName) +
                                                " "
                                            ),
                                            _c("v-icon", [
                                              _vm._v("keyboard_arrow_right"),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getSelectedSharedwithInstanceLabels
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _vm.bundleTab
                                              ? _c(
                                                  "v-chip",
                                                  { staticClass: "ml-2" },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "mr-2" },
                                                      [_vm._v("mdi-alarm")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.bundleDeadlineDate
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.bundleDeadlineTime
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm.assignmentDescription.length > 0 ||
                                  _vm.notificationText.length > 0
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "text-caption text-uppercase",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.bundleTab
                                                          ? "Assignment description"
                                                          : "Notification for target users"
                                                      ) +
                                                      ": "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("v-col", [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.bundleTab
                                                    ? _vm.assignmentDescription
                                                    : _vm.notificationText
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "text-caption text-uppercase",
                                          },
                                          [_vm._v("Bundled objects:")]
                                        ),
                                      ]),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { elevation: "5" } },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("folder"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-list-item-title", [
                                                    _vm._v("Files"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _vm.orderedStagedObjects.files
                                                .length === 0
                                                ? _c(
                                                    "v-list-item",
                                                    {
                                                      staticClass:
                                                        "justify-center",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column py-5 opacity-3",
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-dots-square"
                                                            ),
                                                          ]),
                                                          _c("span", [
                                                            _vm._v("Empty"),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                _vm.orderedStagedObjects.files,
                                                function (file, i) {
                                                  return [
                                                    _c(
                                                      "v-list-item",
                                                      { key: file.fid },
                                                      [
                                                        _c(
                                                          "v-list-item-icon",
                                                          [
                                                            file.type ===
                                                              _vm
                                                                .nuvolosObjectTypes
                                                                .FOLDER ||
                                                            file.fileType === 2
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " folder "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-file-outline"
                                                                    ),
                                                                  ]
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              file.filePath
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    i <
                                                    _vm.orderedStagedObjects
                                                      .files.length -
                                                      1
                                                      ? _c("v-divider", {
                                                          key: "d" + file.fid,
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { elevation: "5" } },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "desktop_windows"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-list-item-title", [
                                                    _vm._v("Apps"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _vm.orderedStagedObjects
                                                .applications.length === 0
                                                ? _c(
                                                    "v-list-item",
                                                    {
                                                      staticClass:
                                                        "justify-center",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column py-5 opacity-3",
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-dots-square"
                                                            ),
                                                          ]),
                                                          _c("span", [
                                                            _vm._v("Empty"),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                _vm.orderedStagedObjects
                                                  .applications,
                                                function (app, i) {
                                                  return [
                                                    _c(
                                                      "v-list-item",
                                                      { key: app.aid },
                                                      [
                                                        _c(
                                                          "v-list-item-icon",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "desktop_windows"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              app.filePath
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    i <
                                                    _vm.orderedStagedObjects
                                                      .applications.length -
                                                      1
                                                      ? _c("v-divider", {
                                                          key: "d" + app.aid,
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { elevation: "5" } },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-table-large"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-list-item-title", [
                                                    _vm._v("Tables"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _vm.orderedStagedObjects.tables
                                                .length === 0
                                                ? _c(
                                                    "v-list-item",
                                                    {
                                                      staticClass:
                                                        "justify-center",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column py-5 opacity-3",
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-dots-square"
                                                            ),
                                                          ]),
                                                          _c("span", [
                                                            _vm._v("Empty"),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                _vm.orderedStagedObjects.tables,
                                                function (table, i) {
                                                  return [
                                                    _c(
                                                      "v-list-item",
                                                      { key: table.tid },
                                                      [
                                                        _c(
                                                          "v-list-item-icon",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-table-large"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              table.filePath
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    i <
                                                    _vm.orderedStagedObjects
                                                      .tables.length -
                                                      1
                                                      ? _c("v-divider", {
                                                          key: "d" + table.tid,
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "align-center" },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                loading: _vm.loading,
                                                "e2e-staging-step-6": "",
                                              },
                                              on: { click: _vm.distribute },
                                            },
                                            [_vm._v("Share objects")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                disabled: _vm.loading,
                                                color: "secondary",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.stepperStep = 5
                                                },
                                              },
                                            },
                                            [_vm._v("Back")]
                                          ),
                                          _c(
                                            "v-alert",
                                            {
                                              staticClass: "mt-3",
                                              attrs: {
                                                value: _vm.error,
                                                type: "error",
                                                icon: "warning",
                                                outlined: "",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.errorContent))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }